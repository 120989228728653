import React, { useEffect, useState } from 'react';
import PageTemplate from 'template/PageTemplate';
import ReactPlayer from 'react-player';
import { Colors, Metrics } from 'configs';
import {
  Container,
  PlayerContainer,
  ScrollBars,
  ScrollContainer,
  TabPaneStyled,
  TabsStyled,
  TextArea,
  TrainingTitle,
  TrainingTitleContainer,
  UserComment,
} from './styles';
import { useHistory, useParams } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import {
  findCourseVideoTrainingRequest,
  findQuestionRequest,
  getCourseUserProgressRequest,
  getExerciseAnswersRequest,
} from 'services/course';
import { Collapse, Image, Spin, Tabs, Typography } from 'antd';
import { useCourseMarkTrainingCompleted } from 'store/hooks/course';
import { Button, Col, Row, Text, Whitespace } from '../../common';
import {
  CheckCircleOutlined, CloseOutlined,
  CommentOutlined, DeleteOutlined, EditOutlined,
  EnterOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import {Accordion, Flex} from '../../components';
import Exercises from './Exercises';
import {
  activateThoughtRequest,
  createAnswerRequest,
  createThoughtRequest,
  findThoughtsRequest, inactivateAnswerRequest,
  inactivateThoughtRequest, updateAnswerRequest, updateThoughtRequest,
} from '../../services/thoughts';
import answerIcon from '../../assets/images/anwserIcon.png';
import { useAuthUser } from '../../store/hooks/auth';
import CourseSectionHeader from "../../components/CourseSectionHeader";
import CourseSectionContent from "../../components/CourseSectionContent";

const { TabPane } = Tabs;
const { Panel } = Collapse;


const { Paragraph } = Typography;

const CourseWatch = () => {
  const user = useAuthUser();
  const history = useHistory();
  const [questions, setQuestions] = useState(null);
  const [previouslyCompleted, setPreviouslyCompleted] = useState(null);
  const { trainingId, courseId } = useParams();
  const markTrainingCompleted = useCourseMarkTrainingCompleted();
  const [thought, setThought] = useState('');
  const [answer, setAnswer] = useState('');
  const [answering, setAnswering] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editingAnswer, setEditingAnswer] = useState(false);
  const [edit, setEdit] = useState('');
  const [editAnswer, setEditAnswer] = useState('');
  const [tabIndex, setTabIndex] = useState('0');

  const [
    { isFetching: isFetchingCourse, data: dataCourse },
    fetchCourseProgress,
  ] = useFetch(getCourseUserProgressRequest, courseId, true, null, () =>
    history.goBack()
  );
  const [
    { isFetching: isFetchingTraining, data: dataTraining }, fetchVideoTraining
  ] = useFetch(findCourseVideoTrainingRequest, trainingId, true, null, () =>
    history.goBack()
  );
  const [
    { isFetching: isFetchingThoughts, data: dataThoughts }, findThoughts
  ] = useFetch(findThoughtsRequest, trainingId, true, [], () =>
    history.goBack()
  );
  const [
    { isFetching: isFetchingThoughtCreate, data: dataThoughtCreate }, createThought
  ] = useFetch(createThoughtRequest, trainingId, false, null, () =>
    history.goBack()
  );
  const [
    { isFetching: isFetchingAnswerCreate, data: dataAnswerCreate }, createAnswer
  ] = useFetch(createAnswerRequest, '', false, null, () =>
    history.goBack()
  );
  const [
    { isFetching: isFetchingInactivateThought, data: dataThoughtInactivate }, inactivateThought
  ] = useFetch(inactivateThoughtRequest, '', false, null, () =>
    history.goBack()
  );
  const [
    { isFetching: isFetchingInactivateAnswer, data: dataAnswerInactivate }, inactivateAnswer
  ] = useFetch(inactivateAnswerRequest, '', false, null, () =>
    history.goBack()
  );
  const [
    { isFetching: isFetchingActivateThought, data: dataThoughtActivate }, activateThought
  ] = useFetch(activateThoughtRequest, '', false, null, () =>
    history.goBack()
  );
  const [
    { isFetching: isFetchingUpdateThought, data: dataThoughtUpdate }, updateThought
  ] = useFetch(updateThoughtRequest, '', false, null, () =>
    history.goBack()
  );  const [
    { isFetching: isFetchingUpdateAnswer, data: dataAnswerUpdate }, updateAnswer
  ] = useFetch(updateAnswerRequest, '', false, null, () =>
    history.goBack()
  );
  const [{isFetching: isFetchingAnswers, data: savedAnswers}, fetchSaved] = useFetch(
    getExerciseAnswersRequest,
    trainingId,
    true,
    null,
  );

  useEffect(() => {
    if (trainingId) {
      setPreviouslyCompleted(false);
      fetchCourseProgress();
      fetchVideoTraining();
      fetchSaved();
    }
  }, [trainingId]);

  useEffect(() => {
    if (dataThoughtCreate || dataAnswerCreate || dataThoughtInactivate || dataThoughtUpdate || dataAnswerUpdate || dataAnswerInactivate || trainingId) {
      findThoughts();
    }
  }, [dataThoughtCreate, dataAnswerCreate, dataThoughtInactivate, dataThoughtUpdate, dataAnswerUpdate, dataAnswerInactivate, trainingId]);

  useEffect(() => {
    if (savedAnswers) {
      const questions = {};
      if (savedAnswers.length > 0) {
        setPreviouslyCompleted(true);
      } else {
        setPreviouslyCompleted(false);
      }
      savedAnswers.forEach((ad) => {
        questions[ad.questaoId] = ad.alternativaId;
      });
      setQuestions(questions);
    }
  }, [savedAnswers]);

  if (isFetchingTraining || isFetchingCourse) {
    return (
      <div style={styles.spinContainer}>
        <Spin />
      </div>
    );
  }

  const endedWatch = () => {
    if (!dataTraining.treinamento?.is_in_person) {
      markTrainingCompleted(trainingId);
    }
  }

  return (
    <PageTemplate containerStyle={{ paddingBottom: "2vw", height: '100%' }} contentStyle={{ height: '100%'}}>
      <Container>
        <PlayerContainer>

          {dataTraining && dataTraining.arquivo && (
            <ReactPlayer
              url={dataTraining.arquivo.nm_url}
              controls
              width="100%"
              height="auto"
              onEnded={endedWatch}
              onContextMenu={(e) => e.preventDefault()}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                    disablePictureInPicture: true,
                  },
                },
              }}
            />
          )}

          {dataTraining && dataTraining.antecessor && (
            <div
              style={styles.warningBlock}>
              <div style={styles.warningIconsBlock}>
                <ExclamationCircleOutlined
                  style={styles.warningIcon}
                />
                <Whitespace width={Metrics.spacing.md} />
                <VideoCameraOutlined
                  style={styles.warningIcon}
                />
              </div>
              <Whitespace height={Metrics.spacing.md} />
              <Text size={Metrics.fontSize.sm} color={Colors.primary}>
                Para assistir a este treinamento, é necessário completar os seus
                antecessores.
              </Text>
              <Whitespace height={Metrics.spacing.sm} />
              <Text
                bolder
                size={Metrics.fontSize.sm}
                color={Colors.primary}>
                {`Dica: assista primeiro ao treinamento "${dataTraining.antecessor.ds_titulo}"`}
              </Text>
              <Whitespace height={Metrics.spacing.lg} />
              <Button onClick={() =>                             history.push(
                `/course-video/${courseId}/${+dataTraining.antecessor.treinamento_id}`
              )}>Entendi!</Button>
            </div>
          )}
          {questions && <Exercises data={trainingId}
                     questions={questions}
                     completeFn={markTrainingCompleted}
                     previouslyCompleted={previouslyCompleted} />}
        </PlayerContainer>
        <div style={styles.allLesson}>
{/*          <div style={styles.allLesson.header}>
            <span style={styles.allLesson.header.title}>Todas as aulas</span>

          </div>*/}
          {dataCourse && (
            <TabsStyled
              style={{
                padding: `0 ${Metrics.spacingMD}`,
                borderRadius: Metrics.defaultRadius,
                height: '100%'
              }}
              onTabClick={k => {
                setTabIndex(k);
              }}
              activeKey={tabIndex}>
              <TabPaneStyled tab="Todas as aulas" key="0">
                            <span style={styles.allLesson.header.text}>
              Clique para escolher
            </span>
                <ScrollContainer>
                  <Col style={{overflowY: 'scroll', paddingRight: Metrics.spacingMD, paddingBottom: Metrics.spacingMD, maxWidth: '340px'}}>
                    {dataCourse.topico.map((t, i) => (
                      <Accordion
                      key={i}
                      active={t.usuarioTreinamento.map( ut => ut.treinamento_id).includes(+trainingId)}
                      header={<CourseSectionHeader small ds={t} />}
                      content={
                        <Col style={{paddingBottom: Metrics.spacingMD}}>
                          <TrainingTitleContainer>
                            {t.usuarioTreinamento.map((v, i2) => (
                              <TrainingTitle
                                key={i2}
                                last={i2 === t.usuarioTreinamento.length - 1}
                                viewed={v.fg_concluido}
                                currentTraining={+v.treinamento_id === +trainingId}
                                onClick={() =>
                                  history.push(
                                    `/course-video/${courseId}/${+v.treinamento_id}`
                                  )
                                }
                              >
                                <div className="circle">
                                  <div />
                                </div>
                                <div className="circle-guide" />
                                <Paragraph
                                  ellipsis={{ rows: 1 }}
                                  style={{ fontSize: Metrics.fontSize.xxsm }}>
                                  {v.ds_titulo}
                                </Paragraph>
                              </TrainingTitle>
                            ))}
                          </TrainingTitleContainer>
                        </Col>
                      }
                      />
                    ))}
                    {/*{dataCourse.topico.map((t, i) => (
                      <div
                        style={styles.session}
                        key={i}
                        className={
                          i === dataCourse.topico.length - 1 ? "last-topic" : ""
                        }
                      >
                        <div style={styles.session.title}>{t.ds_titulo}</div>
                        <TrainingTitleContainer>
                          {t.usuarioTreinamento.map((v, i2) => (
                            <TrainingTitle
                              key={i2}
                              last={i2 === t.usuarioTreinamento.length - 1}
                              viewed={v.fg_concluido}
                              currentTraining={+v.treinamento_id === +trainingId}
                              onClick={() =>
                                history.push(
                                  `/course-video/${courseId}/${+v.treinamento_id}`
                                )
                              }
                            >
                              <div className="circle">
                                <div />
                              </div>
                              <div className="circle-guide" />
                              <Paragraph
                                ellipsis={{ rows: 1 }}
                                style={{ fontSize: Metrics.fontSize.xsm }}>
                                {v.ds_titulo}
                              </Paragraph>
                            </TrainingTitle>
                          ))}
                        </TrainingTitleContainer>
                      </div>
                    ))}*/}
                  </Col>
                </ScrollContainer>
              </TabPaneStyled>
              <TabPane tab="Reflexões" key="1">
                <ScrollContainer>
                  <ScrollBars>
                    <Flex dir="column"  marginRight="25px" paddingBottom="15px">
                    <span style={{ fontWeight: 300, color: Colors.secondary }}>
                      Escreve sua reflexão sobre o que foi abordado neste vídeo.
                    </span>
                      <TextArea
                        value={thought}
                        onChange={(e) => setThought(e.target.value)}
                        placeholder="Digite sua reflexão"
                        style={{ margin: "20px 0" }}
                      />
                      <Button style={styles.sendButton} disabled={!thought || (thought && thought.length < 2)} onClick={() => {
                        createThought({ds_texto: thought});
                        setThought('');
                      }}>Enviar</Button>
                      {dataThoughts.map((dt, i) => (
                        <Flex key={i} dir="column" marginTop="12px">
                          <Flex dir="row">
                            <div style={styles.userImage}><UserOutlined style={{color: Colors.softGray}} /></div>
                            <Col style={{width: '100%'}}>
                              <UserComment>
                                {dt.ds_texto}
                              </UserComment>
                              { editing === dt.id &&
                                <Col>
                                  <TextArea
                                    value={edit}
                                    onChange={(e) => setEdit(e.target.value)}
                                    placeholder="Digite sua reflexão"
                                    style={{ margin: "20px 0" }}
                                  />
                                  <Row>
                                    <Button width='100px' style={styles.sendButton} onClick={() => {
                                      setEdit('');
                                      setEditing(false);
                                    }}>Cancelar</Button>
                                    <Button  width='100px' style={{...styles.sendButton, marginLeft: '10px'}}
                                      disabled={!edit || (edit && edit.length < 2)} onClick={() => {
                                      updateThought({thought_id: dt.id, ds_texto: edit});
                                      setEdit('');
                                      setEditing('');
                                    }}>Atualizar</Button>
                                  </Row>
                                </Col>
                              }
                            </Col>
                          </Flex>
                          <Flex dir="row" justifyContent='flex-end' alignItems='center'>
                            { user.admin &&
                              <CheckCircleOutlined onClick={ () => {
                                setAnswering(a => a === i ? '' : i);
                                setAnswer('');
                                setEditing(false);
                                setEdit('');
                                setEditingAnswer(false);
                                setEditAnswer('');
                              }} />
                            }
                            { (user.admin || user.id === dt.user_id) &&
                              <CloseOutlined onClick={ () => inactivateThought(dt.id)} />
                            }
                            { user.id === dt.user_id &&
                              <EditOutlined onClick={() =>
                                {
                                  setEdit(dt.ds_texto);
                                  setEditing(dt.id);
                                  setAnswering(false);
                                  setAnswer('');
                                  setEditingAnswer(false);
                                  setEditAnswer('');
                                }
                              } />
                            }
                            {
                              user.id !== dt.user_id &&
                              <CommentOutlined onClick={ () => {
                                  setAnswer('');
                                  setAnswering(a => a === i ? '' : i);
                                  setEditing(false);
                                  setEdit('');
                                  setEditingAnswer(false);
                                  setEditAnswer('');
                                }
                              } />
                            }
                            <Row style={styles.userTimeRow}>
                              <span style={{...styles.user}}>{`${dt.usuarios.username} -`}</span>
                              <span style={styles.time}>{dt.created_at}</span>
                            </Row>
                          </Flex>
                          { answering === i &&
                            <Flex dir="column">
                              <TextArea
                                value={answer}
                                onChange={(e) => setAnswer(e.target.value)}
                                placeholder="Digite sua resposta"
                                style={{ margin: "20px 0" }}
                              />
                              <Row>
                                <Button width='100px' style={styles.sendButton} onClick={() => {
                                  setAnswering(false);
                                  setAnswer('');
                                }}>Cancelar</Button>
                                <Button width='100px' style={{...styles.sendButton, marginLeft: '10px'}}
                                  disabled={!answer || (answer && answer.length < 2)}
                                  onClick={() => {
                                  createAnswer({reflexao_id: dt.id, ds_texto: answer});
                                  setAnswering(false);
                                  setAnswer('');
                                }}>Enviar</Button>
                              </Row>
                            </Flex>
                          }

                          {dt.reflexaoReposta && dt.reflexaoReposta.map( (rr, y) =>
                            <Flex key={y} dir="column" marginTop="5px" justifyContent='flex-end' alignItems='flex-end'>
                              <Flex dir="row" width='80%'>
                                <div style={styles.userImage}><UserOutlined style={{color: Colors.softGray}} /></div>
                                <Col style={{width: '100%'}}>
                                <UserComment>
                                  {rr.ds_texto}
                                </UserComment>
                                { editingAnswer === rr.id &&
                                <Col>
                                  <TextArea
                                    value={editAnswer}
                                    onChange={(e) => setEditAnswer(e.target.value)}
                                    placeholder="Digite sua resposta"
                                    style={{ margin: "20px 0" }}
                                  />
                                  <Row>
                                    <Button smallPadding width='60px' style={styles.sendButton} onClick={() => {
                                      setEditAnswer('');
                                      setEditingAnswer(false);
                                    }}>Cancelar</Button>
                                    <Whitespace width={Metrics.spacing.min} />
                                    <Button smallPadding width='60px' style={{...styles.sendButton, marginLeft: '10px'}}
                                      disabled={!editAnswer || (editAnswer && editAnswer.length < 2)}
                                      onClick={() => {
                                      updateAnswer({answer_id: rr.id, ds_texto: editAnswer});
                                      setEditAnswer('');
                                      setEditingAnswer(false);
                                    }}>Atualizar</Button>
                                  </Row>
                                </Col>
                                }
                                </Col>
                              </Flex>
                              <Flex dir="row" justifyContent='flex-end' alignItems='center'>
                                { (user.admin || user.id === rr.user_id) &&
                                  <CloseOutlined onClick={ () => inactivateAnswer(rr.id)} />
                                }
                                { user.id === rr.user_id &&
                                  <EditOutlined onClick={() =>
                                    {
                                      setEditAnswer(rr.ds_texto);
                                      setEditingAnswer(rr.id);
                                      setEditing(false);
                                      setEdit('');
                                      setAnswering(false);
                                      setAnswer('');
                                    }
                                } />
                                }
                                <Row style={styles.userTimeRow}>
                                  <span style={{...styles.user}}>{`${rr.usuarios.username} -`}</span>
                                  <span style={styles.time}>{rr.created_at}</span>
                                </Row>
                              </Flex>
                            </Flex>
                            )
                          }
                        </Flex>
                      ))}
                      {/*<span style={styles.loadMore}>Carregar mais</span>*/}
                    </Flex>
                  </ScrollBars>
                </ScrollContainer>
              </TabPane>
            </TabsStyled>
          )}
        </div>
      </Container>
    </PageTemplate>
  );
};

const styles = {
  warningBlock: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.white,
    border: `2px solid ${Colors.primary}`,
    borderRadius: Metrics.defaultRadius,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: Metrics.spacing.sm,
  },
  warningIconsBlock: {display: 'flex', flexDirection: 'row'},
  warningIcon: {color: Colors.white, fontSize: Metrics.fontSize.xxlg},
  allLesson: {
    flex: 1,
    boxShadow: "1px 2px 2px #00000029",
    marginLeft: "2vw",
    marginBottom: "5px",
    backgroundColor: Colors.white,
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1vw",
      borderBottom: `2px solid ${Colors.primary}`,
      title: {
        color: Colors.primary,
        fontSize: Metrics.fontSize.md,
        fontWeight: 600,
      },
      text: {
        textAlign: "right",
        color: Colors.secondary,
        fontSize: "12px",
      },
    },
  },
  session: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
    title: {
      fontSize: Metrics.fontSize.sm,
      fontWeight: 600,
      color: Colors.primary,
    },
    videoName: {
      fontSize: "14px",
      color: Colors.secondary,
    },
  },
  checkIcon: {
    color: Colors.greenCheck,
    fontSize: "25px",
    margin: "0",
    padding: "0",
    top: "auto",
    bottom: "auto",
  },
  exerciseText: {
    color: Colors.secondary,
    fontSize: "15px",
  },
  loadMore: {
    marginLeft: "auto",
    marginRight: "auto",
    color: Colors.primary,
    fontWeight: 300,
    cursor: "pointer",
    textDecoration: "underline",
    fontSize: Metrics.fontSize.xsm,
  },
  userTimeRow: {
    alignItems: 'center',
    marginLeft: 'auto',
    marginTop: "5px",
  },
  user: {
    fontSize: Metrics.fontSize.xxsm,
    color: "#B9B9B9",
    fontWeight: 600,
  },
  time: {
    fontSize: Metrics.fontSize.xxxsm,
    color: "#B9B9B9",
    fontWeight: 600,
    marginLeft: Metrics.spacingMinimun
  },
  userImage: {
    width: "25px",
    height: "25px",
    minWidth: "25px",
    minHeight: "25px",
    borderRadius: "100%",
    border: `1px solid ${Colors.softGray}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  sendButton: {
    marginLeft: "auto",
    marginBottom: "10px",
  },
  reflectionsContainer: {
    flex: 1,
    borderRadius: "20px",
    marginLeft: "2vw",
  },
  spinContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default CourseWatch;
