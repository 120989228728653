import { Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import history from "routes/history";
import Route from "routes/Route";
import Login from "features/Login";
import Landing from "features/Landing";
import SystemLayout from "features/Layout";
import NotPrivateLayout from "features/NotPrivateLayout";
import Register from "features/Register";
import CourseDetail from "features/Courses/CourseDetail";
import CoursePublicDetail from "features/Courses/CoursePublicDetail";
import Courses from "features/Courses";
import { useAuthAuthenticated } from "store/hooks/auth";
import Profile from "features/Profile";
import PrivacyPolicy from "features/PrivacyPolicy";
import ChangePassword from "features/ChangePassword";
import ChangePasswordEmailCpf from "features/ChangePasswordEmailCpf";
import Help from "features/Help";
import PaymentMethod from "features/PaymentMethod";
import CourseProgress from "../features/Courses/CourseProgress";
import Home from "../features/Home";
import Certificates from "features/Certificates";
import CourseWatch from "features/CourseWatch";
import CourseBuy from "../features/Courses/CourseBuy";
import CoursesCompletedAndProgress from "features/CoursesCompletedAndProgress";
import FirstAccess from "features/FirstAccess";
import UserCredentials from "features/UserCredentials";
import CoursesAdm from "../features/Backoffice/Courses";
import BackofficeCourse from "../features/Backoffice/Courses";
import BackofficeCourseEdit from "../features/Backoffice/Courses/Edit";
import BackofficeCourseCreate from "../features/Backoffice/Courses/Create";
import BackofficeTopics from "../features/Backoffice/Topics";
import BackofficeTopicEdit from "../features/Backoffice/Topics/Edit";
import BackofficeTopicCreate from "../features/Backoffice/Topics/Create";
import BackofficeTrainingEdit from "../features/Backoffice/Trainings/Edit";
import BackofficeTrainingCreate from "../features/Backoffice/Trainings/Create";
import BackofficeTraining from "../features/Backoffice/Trainings";
import BackofficeQuestion from "../features/Backoffice/Question";
import BackofficeQuestionEdit from "../features/Backoffice/Question/Edit";
import BackofficeQuestionCreate from "../features/Backoffice/Question/Create";
import BackofficeUser from "../features/Backoffice/Users";
import BackofficeUserCreate from "../features/Backoffice/Users/Create";
import BackofficeUserEdit from "../features/Backoffice/Users/Edit";
import BackofficeCounties from "../features/Backoffice/Counties";
import BackofficeParishes from "../features/Backoffice/Parishes";
import BackofficeCountiesCreate from "../features/Backoffice/Counties/Create";
import BackofficeCountiesEdit from "../features/Backoffice/Counties/Edit";
import BackofficeParishesCreate from "../features/Backoffice/Parishes/Create";
import BackofficeParishesEdit from "../features/Backoffice/Parishes/Edit";
import BackofficeMaritalStatus from "features/Backoffice/MaritalStatus";
import BackofficeMaritalStatusCreate from "features/Backoffice/MaritalStatus/Create";
import BackofficeMaritalStatusEdit from "features/Backoffice/MaritalStatus/Edit";
import BackofficeFrequentlyAskedQuestion from "features/Backoffice/FrequentlyAskedQuestion";
import BackofficeFrequentlyAskedQuestionCreate from "features/Backoffice/FrequentlyAskedQuestion/Create";
import BackofficeFrequentlyAskedQuestionEdit from "features/Backoffice/FrequentlyAskedQuestion/Edit";
import BackofficeCourseReport from "features/Backoffice/CourseReport";
import PaymentMethodCreate from "features/PaymentMethod/Create";
import PaymentMethodEdit from "features/PaymentMethod/Edit";
import FinancialData from "features/FinancialData";
import { Certificate } from "features/Courses/certificate";
import PersonTraining from "features/Backoffice/PersonTraining";
import PersonTrainingStudents from "features/Backoffice/PersonTraining/Students";
import { CourseModules } from "features/Backoffice/CourseModules";
import { CourseModulesTopics } from "features/Backoffice/CourseModules/Topics";
import { CourseModulesStudents } from "features/Backoffice/CourseModules/Students";
import BackofficeCoursesReport from "features/Backoffice/CoursesReports";
import { RecoverPasswordWithHash } from "features/RecoverPasswordHash";
import { BackOfficeAllCoursesReport } from "features/Backoffice/AllCoursesReports";

const sysRoutes = [
  {
    path: "/backoffice/course",
    component: BackofficeCourse,
  },
  {
    path: "/backoffice/course/create",
    component: BackofficeCourseCreate,
  },
  {
    path: "/backoffice/course/edit/:id",
    component: BackofficeCourseEdit,
  },
  {
    path: "/backoffice/topic",
    component: BackofficeTopics,
  },
  {
    path: "/backoffice/topic/create",
    component: BackofficeTopicCreate,
  },
  {
    path: "/backoffice/topic/edit/:id",
    component: BackofficeTopicEdit,
  },
  {
    path: "/backoffice/training",
    component: BackofficeTraining,
  },
  {
    path: "/backoffice/training/create",
    component: BackofficeTrainingCreate,
  },
  {
    path: "/backoffice/training/edit/:id",
    component: BackofficeTrainingEdit,
  },
  {
    path: "/backoffice/question",
    component: BackofficeQuestion,
  },
  {
    path: "/backoffice/question/create",
    component: BackofficeQuestionCreate,
  },
  {
    path: "/backoffice/question/edit/:id",
    component: BackofficeQuestionEdit,
  },
  {
    path: "/backoffice/user",
    component: BackofficeUser,
  },
  {
    path: "/backoffice/user/create",
    component: BackofficeUserCreate,
  },
  {
    path: "/backoffice/user/edit/:id",
    component: BackofficeUserEdit,
  },
  {
    path: "/backoffice/county",
    component: BackofficeCounties,
  },
  {
    path: "/backoffice/county/create",
    component: BackofficeCountiesCreate,
  },
  {
    path: "/backoffice/county/edit/:id",
    component: BackofficeCountiesEdit,
  },
  {
    path: "/backoffice/parish",
    component: BackofficeParishes,
  },
  {
    path: "/backoffice/parish/create",
    component: BackofficeParishesCreate,
  },
  {
    path: "/backoffice/parish/edit/:id",
    component: BackofficeParishesEdit,
  },
  {
    path: "/backoffice/marital-status",
    component: BackofficeMaritalStatus,
  },
  {
    path: "/backoffice/marital-status/create",
    component: BackofficeMaritalStatusCreate,
  },
  {
    path: "/backoffice/marital-status/edit/:id",
    component: BackofficeMaritalStatusEdit,
  },
  {
    path: "/backoffice/frequently-asked-question",
    component: BackofficeFrequentlyAskedQuestion,
  },
  {
    path: "/backoffice/frequently-asked-question/create",
    component: BackofficeFrequentlyAskedQuestionCreate,
  },
  {
    path: "/backoffice/frequently-asked-question/edit/:id",
    component: BackofficeFrequentlyAskedQuestionEdit,
  },
  {
    path: "/backoffice/person-training",
    component: PersonTraining,
  },
  {
    path: "/backoffice/person-training/trainig/:id",
    component: PersonTrainingStudents,
  },
  {
    path: "/backoffice/course-modules",
    component: CourseModules,
  },
  {
    path: "/backoffice/course-modules/topics/:id",
    component: CourseModulesTopics,
  },
  {
    path: "/backoffice/course-modules/topics/:idCourse/:idTopic",
    component: CourseModulesStudents,
  },
  {
    path: "/backoffice/student-report",
    component: BackofficeCourseReport,
  },
  {
    path: "/course-detail/:id",
    component: CourseDetail,
  },
  {
    path: "/course-buy/:id",
    component: CourseBuy,
  },
  {
    path: "/course-buy/:id/:topic_id",
    component: CourseBuy,
  },
  {
    path: "/course-progress/:id",
    component: CourseProgress,
  },
  {
    path: "/course-certificate/:id",
    component: Certificate,
  },
  {
    path: "/course",
    component: Courses,
  },
  {
    path: "/profile",
    component: Profile,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/change-password",
    component: ChangePassword,
  },
  {
    path: "/changePasswordByEmailCpf",
    component: ChangePasswordEmailCpf,
  },
  {
    path: "/help",
    component: Help,
  },
  {
    path: "/financial-data",
    component: FinancialData,
  },
  {
    path: "/payment-method",
    component: PaymentMethod,
  },
  {
    path: "/payment-method/create",
    component: PaymentMethodCreate,
  },
  {
    path: "/payment-method/edit/:id",
    component: PaymentMethodEdit,
  },
  {
    path: "/my-courses",
    component: Home,
  },
  {
    path: "/my-courses-completed",
    component: CoursesCompletedAndProgress,
  },
  {
    path: "/my-courses-progress",
    component: () => {
      return <CoursesCompletedAndProgress completed={false} />;
    },
  },
  {
    path: "/certificates",
    component: Certificates,
  },
  {
    path: "/course-video/:courseId/:trainingId",
    component: CourseWatch,
  },
  {
    path: "/first-access",
    component: FirstAccess,
  },
  {
    path: "/user-credentials",
    component: UserCredentials,
  },
  {
    path: "/backoffice/course-report",
    component: BackofficeCoursesReport,
  },
  {
    path: "/backoffice/course-report-all",
    component: BackOfficeAllCoursesReport,
  },
];

const routes = [
  {
    path: "/login",
    component: Login,
    isLoginOrRegister: true,
  },
  {
    path: "/open-privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/register",
    component: Register,
    isLoginOrRegister: true,
  },
  {
    path: "/changePassword",
    component: ChangePassword,
  },
  {
    path: "/changePasswordEmailCpf",
    component: ChangePasswordEmailCpf,
  },
  {
    path: "/course-public-detail/:id",
    component: CoursePublicDetail,
  },
  {
    path: "/recuperar-senha/:hash",
    component: RecoverPasswordWithHash,
  },
  {
    path: "/",
    component: Landing,
  },
];

export default () => {
  const authenticated = useAuthAuthenticated();

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {sysRoutes.map((r) => (
          <Route
            key={r.path}
            path={r.path}
            component={PrivateRoutes({
              component: r.component,
              layout: SystemLayout,
            })}
            exact
            isPrivate
          />
        ))}
        {routes.map((r) => (
          <Route
            key={r.path}
            path={r.path}
            component={RoutesWithLayout({
              component: r.component,
              layout: NotPrivateLayout,
            })}
            isLoginOrRegister={r.isLoginOrRegister}
            exact
          />
        ))}
        <Route
          component={RoutesWithLayout({
            component: authenticated ? Home : Landing,
            layout: authenticated ? SystemLayout : NotPrivateLayout,
          })}
          path="*"
        />
      </Switch>
    </ConnectedRouter>
  );
};

const PrivateRoutes = ({ component: Component, rest }) => {
  return () => {
    return <SystemLayout>{Component && <Component {...rest} />}</SystemLayout>;
  };
};

const RoutesWithLayout = ({ component: Component, rest, layout: Layout }) => {
  return () => {
    return <Layout>{Component && <Component {...rest} />}</Layout>;
  };
};
