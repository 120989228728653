import * as Excel from "exceljs";
import Moment from "moment";

export async function generateReportXLSX(data, year) {
  let workbook = new Excel.Workbook();
  let worksheet = workbook.addWorksheet("Relatório Cursos");


  const maxId = Math.max(...data.map((el) => el["curso_id"] && el["curso_id"].length));
  const maxFone = Math.max(...data.map((el) => el["user_phone"] && el["user_phone"].length));
  const maxDate = Math.max(...data.map((el) => el["created_at"] && el["created_at"].length));
  const maxUsername = Math.max(...data.map((el) => el["user_name"] && el["user_name"].length));
  const maxComarca = Math.max(...data.map((el) => el["comarca_name"] && el["comarca_name"].length));
  const maxCourseName = Math.max(...data.map((el) => el["course_name"] && el["course_name"].length));
  const maxParoquia = Math.max(...data.map((el) => el["paroquia_name"] && el["paroquia_name"].length));
  const maxProgress = Math.max(...data.map((el) => el["course_progress"] && el["course_progress"].length));
  const maxCode = Math.max(...data.map((el) => el["codigo_promocional"] && el["codigo_promocional"].length));

  worksheet.columns = [
    { header: "Código curso ", key: "curso_id", width: 14 },
    { header: "Nome ", key: "user_name", width: maxUsername || 12 },
    { header: "Fone ", key: "user_phone", width: maxFone || 12 },
    { header: "Comarca ", key: "comarca_name", width: maxComarca || 12 },
    { header: "Paróquia ", key: "paroquia_name", width: maxParoquia || 12 },
    { header: "Curso ", key: "course_name", width: maxCourseName + 5 || 12 },
    { header: "Data Inscrição", key: "dt_inscricao", width: maxDate || 12 },
    { header: "Código Promocional ", key: "codigo_promocional", width: 20 },
    { header: "Aulas assistidas", key: "course_progress", width: 18 },
  ];

  worksheet.getRow(1).font = { bold: true };

  data.forEach((e) => {
    worksheet.addRow({
      ...e,
    });
  });

  [1, 2, 3, 4, 5, 6, 7, 8, 9].forEach((i) => {
    worksheet.getColumn(i).alignment = { horizontal: "left" };
  });

  const buffer = await workbook.xlsx.writeBuffer();

  const blobData = new Blob([buffer], { type: "application/xlsx" });
  const url = window.URL.createObjectURL(blobData);
  const tempLink = document.createElement("a");
  tempLink.href = url;
  tempLink.setAttribute("download", `relatorio-cursos-${year}.xlsx`);
  tempLink.click();
}
