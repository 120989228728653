import React, { useEffect, useState } from "react";

import { Formik } from "formik";
import { Form } from "formik-antd";
import styled from "styled-components";
import { Space, Button, Card } from "antd";

import { Colors } from "configs";
import PageTemplate from "template/PageTemplate";
import { getAllReportsCourses, getReportsCourses } from "services/reports";
import { generateReportXLSX } from "./ReportGenerator";
import { FormDropdown, FormButtons, Row } from "common";
import ResultFilter from "../../../components/ResultFilter/index";
import { Toast } from "../../../utils";

const CustomSpace = styled(Space)`
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`;

export function BackOfficeAllCoursesReport() {
  const [loading, setLoading] = useState(false);
  const [listYears, setListYears] = useState([]);
  const [coursesReport, setCoursesReport] = useState([]);
  const [year, setYear] = useState(0);

  const handleFilter = async (value) => {
    try {
      setLoading(true);
      if (!value.year) {
        Toast.showWarnMessage("Ano inválido!");
        return;
      };

      setYear(value.year);
      const {data} = await getAllReportsCourses(value.year);

      const newData = data.sort((a, b) => a.dt_inscricao - b.dt_inscricao);
      setCoursesReport(newData);
    } catch (error) {
      Toast.showErrorMessage("Erro ao buscar relatório!");
    } finally {
      setLoading(false);
    }
  };

  function exportReport() {
    generateReportXLSX(coursesReport, year);
  }

  const getYears = () => {
    const years = [];
    const date = new Date();
    const currentYear = date.getFullYear();

    for (let index = 2021; index <= currentYear; index++) {
      years.push({
        label: index,
        value: index,
      });
    }

    const sortYears = years.sort((a, b) => b.value - a.value);

    setListYears(sortYears);
  };

  useEffect(() => {
    getYears();
  }, []);

  return (
    <PageTemplate title="Relatório curso anual" backButton={false}>
      <ResultFilter opened>
        <Formik initialValues={{ year: "" }} onSubmit={(values) => handleFilter(values)}>
          {({ handleSubmit, handleReset, values }) => (
            <Form>
              <CustomSpace direction="horizontal">
                <FormDropdown placeholder="Ano" name="year" value={values.year} list={listYears} />
              </CustomSpace>
              <FormButtons bt2={{ label: "Buscar", onClick: handleSubmit, loading: loading, disabled: loading }} bt1={{ label: "Limpar", onClick: handleReset }} />
            </Form>
          )}
        </Formik>
      </ResultFilter>

      {coursesReport?.length > 0 && (
        <Card>
          <Row
            style={{
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span>Total de items: {coursesReport?.length}</span>
            <Button
              style={{
                backgroundColor: Colors.primary,
                borderColor: Colors.primary,
                color: Colors.white,
              }}
              onClick={exportReport}
            >
              Exportar Relatório
            </Button>
          </Row>
        </Card>
      )}
    </PageTemplate>
  );
}
